<template>
  <div>
    <v-card class="pa-0 tab-item-card" flat>
      <v-img class="section-title-bg-img" src="../../assets/insurance-responsibility.jpg"></v-img>
      <div class="text-center py-12">
        <span class="text-h3 text-sm-h2 text-md-h1">Insurance responsibilites</span>
      </div>
      <v-container class="px-5 py-5 py-sm-16">
        <p>8.4.1 Not to do anything which might cause the Landlord’s policy of insurance on the Premises or on the Fixtures and Fittings, to become void or voidable or causes the rate of premium on any such policy to be increased. The Tenant will indemnify the Landlord for any sums from time to time paid by way of increased premium and all reasonable expenses incurred by the Landlord in or about any renewal of such policy rendered necessary by a breach of this provision. The Tenant’s belongingswithin the Premises are his and are not covered by any insurance policy maintained by the Landlord.</p>
        <p>8.4.2 The Tenant will promptly notify the Landlord or the Landlord’s Agent of any defect to the Premises, for example in the event of loss or damage by fire, theft or other causes (whether or not caused by the act, default or neglect of the Tenant) of which he becomes aware.</p>
        <p>8.4.3 The Tenant is strongly advised to take out insurance with a reputable insurer for the Tenant’s possessions as such possessions will not be covered by any insurance effected by the Landlord. </p>
        <p>4.This Agreement is intended to create an Assured Shorthold Tenancy as defined by Section 19A of the Housing Act 1988 as amended and shall take effect subject to the provisions for recovery of possession provided for by virtue of Section 21 of that Act.</p>
        <p>5.Where the Tenancy shall include the Landlord’s Fixtures and Fittings ('the Fixtures and Fittings') in the Premises this includes, amongst other things, all matters specified in the Inventory and Schedule of Condition (‘the Check-In Inventory and Schedule of Condition’). </p>
        <p>6.In the event of a default by the Tenant(s) of any of their obligations or responsibilities under the Agreement, the Guarantor(s) (if any) shall become immediately liable to remedy said default. In the event of multiple Guarantors, each shall be Joint and Severally liable.</p>
        <p class="text-h5">8.7 Inflammable substances andequipment</p>
        <p>8.7.1 Not to keep any dangerous or inflammable goods, materials, or substances in or on the Premises apart from those required for general household use</p>
        <p>7.2 Upon the Tenant vacating the Premises and after deduction of all agreed or authorised deductions, the balance of the Deposit shall be refunded to the person or persons outlined at clause 7.1 above.</p>
        <p class="text-right text-h3">etc...</p>

        <v-card class="secondary mt-12 pa-3" outlined flat>
          <v-card-title class="white">
            <span class="text-h5">Confirm my insurance</span>
          </v-card-title>
          <v-card-text class="white pt-3">
            <v-card outlined flat class="pa-3">
              <v-radio-group v-model="insuranceRadioGroup">
              <v-radio
                id="content"                    
                label="Contents insurance policy in place"
              ></v-radio>
              <v-radio
                id="noContent"
                class="mt-4 mb-6"
                label="No contents insurance cover"
              ></v-radio>
              <v-radio
                id="takeOut"
                label="Take out content insurance policy"
              ></v-radio>
            </v-radio-group>
            </v-card>
          </v-card-text>
        </v-card>
      </v-container>
      <div class="secondary text-center py-12">
        <v-container class="px-5 py-8">
          <p class="white--text text-h5 text-sm-h4">The products we offer Tenant Contents Insurance are provided by Van Mildert and we do receive a commission from you purchasing a policy</p>
        </v-container>
      </div>
    </v-card>
    <MainPagination 
      previousPage="/review/landlord"
      nextPage="/review/utilities"
    />
  </div>
</template>

<script>
export default {
  name: 'insurance-responsibility'
}
</script>

<style>

</style>